import { useAuth } from '../contexts/AuthContext';
import { useState, useEffect } from 'react';
import {
	Elements,
	PaymentElement,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Link } from 'react-router-dom';
import {
	faServer,
	faRocket,
	faHeadset,
	faLeaf,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../services/firebase';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// Separate component for the payment form
function PaymentForm({ amount, onCancel }) {
	const stripe = useStripe();
	const elements = useElements();
	const [isProcessing, setIsProcessing] = useState(false);
	const [message, setMessage] = useState('');
	const [email, setEmail] = useState('');

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements || !email) {
			return;
		}

		setIsProcessing(true);

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: `${window.location.origin}/thank-you`,
				receipt_email: email,
				payment_method_data: {
					billing_details: {
						email: email,
					},
				},
			},
		});

		if (error) {
			setMessage(error.message);
		}
	};

	return (
		<form
			onSubmit={handleSubmit}
			className='space-y-4'
		>
			<div className='space-y-4'>
				<input
					type='email'
					required
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					placeholder='Your email address'
					className='w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent'
				/>
				<PaymentElement />
			</div>
			<div className='flex gap-3 justify-end mt-6'>
				<button
					type='button'
					onClick={onCancel}
					className='px-4 py-2 text-gray-600 hover:text-gray-800'
				>
					Cancel
				</button>
				<button
					type='submit'
					disabled={!stripe || isProcessing || !email}
					className='px-6 py-2 bg-teal-600 text-white rounded-lg hover:bg-teal-700 transition-colors disabled:opacity-50'
				>
					{isProcessing ? 'Processing...' : `Pay $${amount / 100}`}
				</button>
			</div>
			{message && (
				<div className='text-red-600 text-sm mt-2'>{message}</div>
			)}
		</form>
	);
}

// Create a wrapper component
function ContributeContent() {
	const { user } = useAuth();
	const [clientSecret, setClientSecret] = useState('');
	const [selectedAmount, setSelectedAmount] = useState(null);
	const [customAmount, setCustomAmount] = useState('');
	const [showCustomInput, setShowCustomInput] = useState(false);
	const stripe = useStripe();
	const elements = useElements();
	const [contributorCount, setContributorCount] = useState(0);

	useEffect(() => {
		const fetchContributorCount = async () => {
			try {
				const statsDoc = await getDoc(doc(db, 'stats', 'contributors'));
				setContributorCount(statsDoc.data()?.count || 0);
			} catch (error) {
				console.error('Error fetching contributor count:', error);
			}
		};

		fetchContributorCount();
	}, []);

	const handleSponsor = async (amount) => {
		try {
			// console.log('Creating payment with amount:', amount);
			const response = await fetch('/.netlify/functions/create-payment', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					amount: amount, // amount is already in cents from handleCustomAmountSubmit
					currency: 'usd',
				}),
			});

			const data = await response.json();

			if (!response.ok) {
				throw new Error(data.error || 'Failed to create payment');
			}

			if (data.clientSecret) {
				setClientSecret(data.clientSecret);
				setSelectedAmount(amount);
			} else {
				throw new Error('No client secret received');
			}
		} catch (error) {
			// console.error('Error:', error);
			// You might want to show this error to the user
			alert(error.message);
		}
	};

	const handleCustomAmountSubmit = (e) => {
		e.preventDefault();
		const amount = Math.floor(parseFloat(customAmount) * 100);
		if (amount >= 100) {
			// Minimum $1
			handleSponsor(amount);
		}
	};

	return (
		<div className='min-h-screen bg-gradient-to-br from-teal-50 via-emerald-50 to-cyan-100 py-20'>
			<div className='max-w-3xl mx-auto px-4'>
				<div className='bg-white/50 backdrop-blur-lg rounded-2xl p-8 shadow-xl border border-white/20'>
					<h1 className='text-3xl font-bold text-gray-900 mb-6'>
						Contribute
					</h1>

					<div className='mb-8'>
						{/* <h2 className='text-xl font-semibold mb-2'>
							Join {contributorCount} contributors supporting
							Product Sketch
						</h2> */}
						<p className='text-gray-600 mb-4'>
							Product Sketch is currently free for everyone while
							we expand our feature set. Support our indie project
							and get lifetime access when we launch paid plans.
						</p>
					</div>

					{/* Founding Members section moved here */}
					<div className='mb-8 bg-teal-50 rounded-xl p-6 border border-teal-100'>
						<h2 className='text-xl font-semibold mb-2 flex items-center gap-2'>
							<span className='text-teal-600'>★</span>
							Become a Founding Member
						</h2>
						<p className='text-gray-600 mb-2'>
							Early supporters who contribute $10 or more during
							our development phase will be granted
							<span className='font-semibold text-teal-700'>
								{' '}
								lifetime access
							</span>{' '}
							to Product Sketch and will be recognized as Founding
							Members.
						</p>
						<p className='text-sm text-gray-500 italic'>
							This is a limited-time offer while we're in early
							development. Limits may apply.
						</p>
					</div>

					<div className='mb-8'>
						<h2 className='text-xl font-semibold mb-2'>
							Support our development
						</h2>
						<p className='text-gray-600 mb-6'>
							If you find Product Sketch valuable and would like
							to support its development, you can contribute any
							amount you'd like. Your support helps us:
						</p>
						<ul className='space-y-3 text-gray-600 mb-6'>
							<li className='flex items-center gap-3'>
								<span className='text-teal-600 text-lg'>
									<FontAwesomeIcon icon={faServer} />
								</span>
								<span>
									Build a robust and reliable infrastructure
								</span>
							</li>
							<li className='flex items-center gap-3'>
								<span className='text-teal-600 text-lg'>
									<FontAwesomeIcon icon={faRocket} />
								</span>
								<span>
									Launch exciting new features and
									capabilities
								</span>
							</li>
							<li className='flex items-center gap-3'>
								<span className='text-teal-600 text-lg'>
									<FontAwesomeIcon icon={faHeadset} />
								</span>
								<span>
									Deliver exceptional customer support
								</span>
							</li>
							<li className='flex items-center gap-3'>
								<span className='text-teal-600 text-lg'>
									<FontAwesomeIcon icon={faLeaf} />
								</span>
								<span>
									Ensure long-term project sustainability
								</span>
							</li>
						</ul>
					</div>

					<div className='space-y-4'>
						{!clientSecret ? (
							<>
								<h3 className='font-medium text-gray-900'>
									Choose an amount to contribute:
								</h3>
								<div className='flex flex-wrap gap-4'>
									{[5, 10, 25, 50].map((amount) => (
										<button
											key={amount}
											onClick={() =>
												handleSponsor(amount * 100)
											}
											className='px-6 py-3 bg-teal-600 text-white rounded-lg hover:bg-teal-700 transition-colors'
										>
											${amount}
										</button>
									))}

									{showCustomInput ? (
										<form
											onSubmit={handleCustomAmountSubmit}
											className='flex items-center gap-2'
										>
											<div className='relative'>
												<span className='absolute left-3 top-1/2 -translate-y-1/2 text-gray-500'>
													$
												</span>
												<input
													type='number'
													min='1'
													step='0.01'
													value={customAmount}
													onChange={(e) =>
														setCustomAmount(
															e.target.value
														)
													}
													className='pl-7 pr-3 py-3 border-2 border-teal-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-transparent'
													placeholder='Amount'
													autoFocus
												/>
											</div>
											<button
												type='submit'
												className='px-4 py-3 bg-teal-600 text-white rounded-lg hover:bg-teal-700 transition-colors'
												disabled={
													!customAmount ||
													parseFloat(customAmount) < 1
												}
											>
												Contribute
											</button>
											<button
												type='button'
												onClick={() => {
													setShowCustomInput(false);
													setCustomAmount('');
												}}
												className='px-3 py-3 text-gray-600 hover:text-gray-800'
											>
												Cancel
											</button>
										</form>
									) : (
										<button
											onClick={() =>
												setShowCustomInput(true)
											}
											className='px-6 py-3 border-2 border-teal-600 text-teal-600 rounded-lg hover:bg-teal-50 transition-colors'
										>
											Custom Amount
										</button>
									)}
								</div>
							</>
						) : (
							<div className='mt-8'>
								<Elements
									stripe={stripePromise}
									options={{
										clientSecret,
										appearance: {
											theme: 'stripe',
											variables: {
												colorPrimary: '#0D9488',
											},
										},
									}}
								>
									<PaymentForm
										amount={selectedAmount}
										onCancel={() => {
											setClientSecret('');
											setSelectedAmount(null);
										}}
										options={{
											payment_method_options: {
												card: {
													setup_future_usage: null, // Disable Link
												},
											},
										}}
									/>
								</Elements>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

// Main export now wrapped with Elements
export default function Contribute() {
	return (
		<Elements stripe={stripePromise}>
			<ContributeContent />
		</Elements>
	);
}
