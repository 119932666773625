import { useState, useEffect, useRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { taskService } from '../services/taskService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faReply, faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { collection, query, where, getDocs, getDoc, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../services/firebase';
import { useAuth } from '../contexts/AuthContext';
import { userService } from '../services/userService';

const EFFORT_LEVELS = {
  low: { label: 'Low Effort', color: 'bg-green-100 text-green-800' },
  high: { label: 'High Effort', color: 'bg-red-100 text-red-800' }
};

const IMPACT_LEVELS = {
  low: { label: 'Low Impact', color: 'bg-yellow-100 text-yellow-800' },
  high: { label: 'High Impact', color: 'bg-blue-100 text-blue-800' }
};

export default function TaskItem({ task, index, projectId, allUsers }) {
  const [isEditing, setIsEditing] = useState(false);
  const [isAssigning, setIsAssigning] = useState(false);
  const [note, setNote] = useState(task.note || '');
  const [users, setUsers] = useState([]);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [noteReplies, setNoteReplies] = useState([]);
  const [newReply, setNewReply] = useState('');
  const { userProfile } = useAuth();
  const dropdownRef = useRef(null);
  const [noteAuthor, setNoteAuthor] = useState('');
  const [isEditingTags, setIsEditingTags] = useState(false);
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [editingTagIndex, setEditingTagIndex] = useState(null);
  const [editingTagValue, setEditingTagValue] = useState('');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState(task.title);
  const [showDueDateModal, setShowDueDateModal] = useState(false);
  const [newDueDate, setNewDueDate] = useState('');
  const [showEffortMenu, setShowEffortMenu] = useState(false);
  const [showImpactMenu, setShowImpactMenu] = useState(false);

  useEffect(() => {
    console.log('Task data:', task);
    console.log('Checking task tags:', task.tags);
    
    if (!Object.prototype.hasOwnProperty.call(task, 'tags')) {
      console.log('Task has no tags property, initializing...');
      const taskRef = doc(db, 'tasks', task.id);
      updateDoc(taskRef, { tags: [] })
        .then(() => {
          console.log('Successfully initialized tags array for new task');
          setTags([]);
        })
        .catch(error => console.error('Error initializing tags:', error));
    } else {
      console.log('Task already has tags property:', task.tags);
      setTags(task.tags?.map(tag => tag.startsWith('#') ? tag.replace('#', '') : tag) || []);
    }
  }, [task.id, task.tags]);

  useEffect(() => {
    const fetchProjectUsers = async () => {
      if (!projectId) return;

      try {
        const members = await userService.getProjectMembers(projectId);
        console.log('Loaded project members:', members);
        setUsers(members);
      } catch (error) {
        console.error('Error fetching project members:', error);
      }
    };

    fetchProjectUsers();

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsAssigning(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [projectId]);

  useEffect(() => {
    console.log('Task:', task);
    console.log('All Users:', allUsers);
  }, [task, allUsers]);

  const handleAddReply = async () => {
    if (!newReply.trim() || !userProfile) return;

    try {
      const reply = {
        content: newReply,
        author: userProfile.username || userProfile.email,
        timestamp: new Date().toISOString()
      };

      const taskRef = doc(db, 'tasks', task.id);
      const taskDoc = await getDoc(taskRef);
      const currentReplies = taskDoc.exists() ? (taskDoc.data().replies || []) : [];
      const updatedReplies = [...currentReplies, reply];

      await updateDoc(taskRef, {
        replies: updatedReplies
      });
      
      setNoteReplies(updatedReplies);
      setNewReply('');
    } catch (error) {
      console.error('Error adding reply:', error);
    }
  };

  if (!task.id) {
    console.error('Task missing ID:', task);
    return null;
  }

  const handleNoteSave = async () => {
    try {
      const taskRef = doc(db, 'tasks', task.id);
      await updateDoc(taskRef, {
        note: note.trim(),
        updatedAt: serverTimestamp()
      });
      setShowNoteModal(false);
    } catch (error) {
      console.error('Error saving note:', error);
    }
  };

  const handleAssigneeChange = async (username) => {
    try {
      const taskRef = doc(db, 'tasks', task.id);
      await updateDoc(taskRef, { assignedTo: username });
      setIsAssigning(false);
    } catch (error) {
      console.error('Error updating assignee:', error);
    }
  };

  const handleShowNoteModal = async () => {
    console.log('Showing note modal');
    try {
      // Get replies directly from the task document
      const taskRef = doc(db, 'tasks', task.id);
      const taskDoc = await getDoc(taskRef);
      
      if (taskDoc.exists()) {
        const taskData = taskDoc.data();
        setNote(taskData.note || '');
        setNoteReplies(taskData.replies || []);
        setNoteAuthor(taskData.noteAuthor || 'Unknown');
        setShowNoteModal(true);
      }
    } catch (error) {
      console.error('Error fetching note and replies:', error);
    }
  };

  const handleDeleteReply = async (replyIndex) => {
    try {
      const taskRef = doc(db, 'tasks', task.id);
      const taskDoc = await getDoc(taskRef);
      
      if (taskDoc.exists()) {
        // Get current replies from state instead of undefined variable
        const updatedReplies = noteReplies.filter((_, index) => index !== replyIndex);
        
        // First update UI for smooth animation
        const replyElement = document.querySelector(`[data-reply-index="${replyIndex}"]`);
        if (replyElement) {
          // Apply the Tailwind animation class
          replyElement.classList.add('animate-slideOut');
      
          // Wait for animation to finish
          await new Promise(resolve => setTimeout(resolve, 1000));
      
          // Now remove the element from the DOM
          replyElement.remove();
        }

        // Update Firestore
        await updateDoc(taskRef, {
          replies: updatedReplies
        });
        
        // Update local state
        setNoteReplies(updatedReplies);
      }
    } catch (error) {
      console.error('Error deleting reply:', error);
    }
  };

  const handleCloseNoteModal = () => {
    setShowNoteModal(false);
    setNewReply('');
  };

  const handleTagSubmit = async (e) => {
    e.preventDefault();
    if (!newTag.trim()) return;

    try {
      const updatedTags = [...tags, newTag.trim()];
      const taskRef = doc(db, 'tasks', task.id);
      await updateDoc(taskRef, {
        tags: updatedTags.map(tag => tag.startsWith('#') ? tag : `#${tag}`)
      });
      setTags(updatedTags);
      setNewTag('');
    } catch (error) {
      console.error('Error updating tags:', error);
    }
  };

  const handleRemoveTag = async (tagToRemove) => {
    try {
      const updatedTags = tags.filter(tag => tag !== tagToRemove);
      const taskRef = doc(db, 'tasks', task.id);
      await updateDoc(taskRef, {
        tags: updatedTags.map(tag => tag.startsWith('#') ? tag : `#${tag}`)
      });
      setTags(updatedTags);
    } catch (error) {
      console.error('Error removing tag:', error);
    }
  };

  const handleTagEdit = async (index, newValue) => {
    if (!newValue.trim()) return;
    
    try {
      const updatedTags = [...tags];
      updatedTags[index] = newValue.trim();
      
      const taskRef = doc(db, 'tasks', task.id);
      await updateDoc(taskRef, {
        tags: updatedTags.map(tag => tag.startsWith('#') ? tag : `#${tag}`)
      });
      
      setTags(updatedTags);
      setEditingTagIndex(null);
      setEditingTagValue('');
    } catch (error) {
      console.error('Error updating tag:', error);
    }
  };

  const handleTitleUpdate = async () => {
    if (!editedTitle.trim() || editedTitle === task.title) {
      setIsEditingTitle(false);
      setEditedTitle(task.title);
      return;
    }

    try {
      const taskRef = doc(db, 'tasks', task.id);
      await updateDoc(taskRef, {
        title: editedTitle.trim(),
        updatedAt: serverTimestamp()
      });
      setIsEditingTitle(false);
    } catch (error) {
      console.error('Error updating task title:', error);
      setEditedTitle(task.title);
      setIsEditingTitle(false);
    }
  };

  const handleInitialTagAdd = async (tagValue) => {
    if (!tagValue.trim()) return;
    
    try {
      const taskRef = doc(db, 'tasks', task.id);
      const newTag = tagValue.trim();
      const updatedTags = [newTag];
      
      await updateDoc(taskRef, {
        tags: updatedTags.map(tag => tag.startsWith('#') ? tag : `#${tag}`)
      });
      
      setTags(updatedTags);
      setEditingTagIndex(null);
      setEditingTagValue('');
    } catch (error) {
      console.error('Error adding initial tag:', error);
    }
  };

  const handleEffortChange = async (newEffort) => {
    try {
      const taskRef = doc(db, 'tasks', task.id);
      await updateDoc(taskRef, {
        effort: newEffort
      });
      setShowEffortMenu(false);
    } catch (error) {
      console.error('Error updating effort:', error);
    }
  };

  const handleImpactChange = async (newImpact) => {
    try {
      const taskRef = doc(db, 'tasks', task.id);
      await updateDoc(taskRef, {
        impact: newImpact
      });
      setShowImpactMenu(false);
    } catch (error) {
      console.error('Error updating impact:', error);
    }
  };

  const handleDueDateSubmit = async () => {
    try {
      const taskRef = doc(db, 'tasks', task.id);
      await updateDoc(taskRef, { dueDate: newDueDate });
      setShowDueDateModal(false);
      setNewDueDate('');
    } catch (error) {
      console.error('Error updating due date:', error);
    }
  };

  const handleDeleteTask = async () => {
    try {
      await taskService.deleteTask(task.id);
      // The real-time listener will handle updating the UI
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const getAssigneeName = (userId) => {
    if (!userId) return 'Unassigned';
    const user = allUsers.find(u => u.id === userId);
    return user ? (user.username || user.email) : userId;
  };

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided, snapshot) => (
        <>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`border border-gray-100 p-2 rounded-lg hover:shadow-md bg-opacity-50 relative ${
              snapshot.isDragging ? 'bg-gray-100 backdrop-blur-sm shadow-lg' : 
              task.tags?.includes('#goal') ? 'bg-red-50' : 'bg-white'
            }`}
          >
            <div className="flex justify-between items-start mb-2">
              <div className="flex items-center gap-2">
                {task.tags?.includes('#goal') && (
                  <button
                    onClick={async () => {
                      const taskRef = doc(db, 'tasks', task.id);
                      const newStatus = task.status === 'completed' ? 'pending' : 'completed';
                      await updateDoc(taskRef, {
                        status: newStatus,
                        updatedAt: serverTimestamp()
                      });
                    }}
                    className={`w-5 h-5 rounded border ${
                      task.status === 'completed'
                        ? 'bg-green-500 border-green-600 text-white'
                        : 'border-gray-400 hover:border-gray-600'
                    } flex items-center justify-center`}
                  >
                    {task.status === 'completed' && <FontAwesomeIcon icon={faCheck} className="w-3 h-3" />}
                  </button>
                )}
                {isEditingTitle ? (
                  <input
                    type="text"
                    value={editedTitle}
                    onChange={(e) => setEditedTitle(e.target.value)}
                    onBlur={handleTitleUpdate}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleTitleUpdate();
                      } else if (e.key === 'Escape') {
                        setIsEditingTitle(false);
                        setEditedTitle(task.title);
                      }
                    }}
                    className="px-2 py-1 text-sm border rounded bg-white focus:outline-none focus:ring-1 focus:ring-blue-500"
                    autoFocus
                  />
                ) : (
                  <span 
                    className={`text-sm font-medium cursor-pointer hover:text-gray-600 ${
                      task.status === 'completed' ? 'line-through' : ''
                    }`}
                    onClick={() => setIsEditingTitle(true)}
                  >
                    {task.title}
                  </span>
                )}
              </div>
              <button
                onClick={handleDeleteTask}
                className="text-red-500 hover:text-red-700 text-xs ml-2"
              >
                ×
              </button>
            </div>
            
            <div className="flex justify-between items-center w-full">
              {/* Tags section */}
              <div className="flex-1">
                <div className="flex items-center gap-1">
                  {tags.map((tag, index) => (
                    <div key={index} className="flex items-center">
                      {editingTagIndex === index ? (
                        <form 
                          onSubmit={(e) => {
                            e.preventDefault();
                            handleTagEdit(index, editingTagValue);
                          }}
                          className="flex items-center"
                        >
                          <input
                            type="text"
                            value={editingTagValue}
                            onChange={(e) => setEditingTagValue(e.target.value)}
                            className="text-xs border border-gray-300 rounded px-1 py-0.5 w-20 focus:outline-none focus:border-blue-500"
                            autoFocus
                            onBlur={() => {
                              setEditingTagIndex(null);
                              setEditingTagValue('');
                            }}
                          />
                        </form>
                      ) : (
                        <>
                          <span 
                            className="text-xs text-blue-600 hover:text-blue-800 cursor-pointer"
                            onClick={() => {
                              setEditingTagIndex(index);
                              setEditingTagValue(tag);
                            }}
                          >
                            {tag ? `#${tag}` : ''}
                          </span>
                          {tag && (
                            <button
                              onClick={() => handleRemoveTag(tag)}
                              className="ml-1 text-xs text-gray-400 hover:text-red-500"
                            >
                              ×
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  ))}

                  {(!tags || tags.length === 0) && (
                    <div
                      onClick={() => {
                        setEditingTagIndex(0);
                        setEditingTagValue('');
                      }}
                      className="text-xs text-gray-400 hover:text-gray-600 cursor-pointer"
                    >
                      {editingTagIndex === 0 ? (
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            handleInitialTagAdd(editingTagValue);
                          }}
                          className="flex items-center"
                        >
                          <input
                            type="text"
                            value={editingTagValue}
                            onChange={(e) => setEditingTagValue(e.target.value)}
                            className="text-xs border border-gray-300 rounded px-1 py-0.5 w-20 focus:outline-none focus:border-blue-500"
                            placeholder="Enter tag"
                            autoFocus
                            onBlur={() => {
                              if (editingTagValue.trim()) {
                                handleInitialTagAdd(editingTagValue);
                              } else {
                                setEditingTagIndex(null);
                                setEditingTagValue('');
                              }
                            }}
                          />
                        </form>
                      ) : (
                        'add a tag'
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/* Divider */}
              <div className="mx-2 text-gray-300">|</div>

              {/* Assignee section */}
              <div className="flex-1">
                <div className="relative flex items-center" ref={dropdownRef}>
                  <div
                    onClick={() => setIsAssigning(!isAssigning)}
                    className="text-xs text-gray-600 cursor-pointer hover:text-gray-900 inline-flex items-center gap-1 group"
                  >
                    <div className="flex items-center gap-1 text-xs text-gray-600">
                      <FontAwesomeIcon icon={faUser} className="w-3 h-3" />
                      {getAssigneeName(task.assignedTo)}
                    </div>
                    <svg 
                      className={`w-3 h-3 transition-transform ${isAssigning ? 'rotate-180' : ''} opacity-50 group-hover:opacity-100`} 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                      strokeWidth={0.8}
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>
                  
                  {isAssigning && (
                    <div className="absolute left-0 mt-1 w-48 bg-white/80 backdrop-blur-lg border border-gray-200 rounded-md shadow-lg z-10">
                      <div className="py-1">
                        <div
                          className="block px-4 py-2 text-xs text-gray-700 hover:bg-gray-100/50 transition-colors cursor-pointer"
                          onClick={() => handleAssigneeChange('')}
                        >
                          Unassign
                        </div>
                        <div
                          className="block px-4 py-2 text-xs text-gray-700 hover:bg-gray-100/50 transition-colors cursor-pointer"
                          onClick={() => handleAssigneeChange(userProfile?.username)}
                        >
                          Me ({userProfile?.username})
                        </div>
                        {users
                          .filter(user => user.username !== userProfile?.username)
                          .map(user => (
                            <div
                              key={user.id}
                              className="block px-4 py-2 text-xs text-gray-700 hover:bg-gray-100/50 transition-colors cursor-pointer"
                              onClick={() => handleAssigneeChange(user.username)}
                            >
                              {user.username}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Divider */}
              <div className="mx-2 text-gray-300">|</div>

              {/* Note section */}
              <div className="flex-1">
                <button
                  onClick={() => {
                    setShowNoteModal(true);
                    setNoteReplies(task.replies || []);
                  }}
                  className="text-xs text-gray-600 hover:text-gray-900 flex items-center gap-1"
                >
                  <FontAwesomeIcon icon={faCommentDots} className="w-3 h-3" />
                  {task.note ? 'View note' : 'Add note'}
                  {task.replies?.length > 0 && (
                    <span className="text-xs text-gray-500">({task.replies.length})</span>
                  )}
                </button>
              </div>
            </div>

            <div className="relative bottom-2 pt-3 flex items-center gap-2">
              {/* Effort */}
              <div className="relative">
                <button
                  onClick={() => setShowEffortMenu(!showEffortMenu)}
                  className="text-xs text-gray-600 hover:text-gray-900"
                >
                  {task.effort ? (
                    <span className={`px-2 py-1 rounded-md text-xs ${EFFORT_LEVELS[task.effort].color}`}>
                      {EFFORT_LEVELS[task.effort].label}
                    </span>
                  ) : (
                    <span className="px-2 py-1 rounded-md text-xs bg-gray-100 border border-gray-200">
                      Set effort
                    </span>
                  )}
                </button>

                {showEffortMenu && (
                  <div className="absolute bottom-full left-0 mb-1 w-32 bg-white/80 backdrop-blur-lg border border-gray-200 rounded-md shadow-lg z-10">
                    {Object.entries(EFFORT_LEVELS).map(([key, { label, color }]) => (
                      <button
                        key={key}
                        onClick={() => handleEffortChange(key)}
                        className={`block w-full text-left px-4 py-2 text-xs hover:bg-gray-100/50 transition-colors ${
                          task.effort === key ? 'font-bold' : ''
                        }`}
                      >
                        <span className={`px-2 py-1 rounded-md ${color}`}>
                          {label}
                        </span>
                      </button>
                    ))}
                    {task.effort && (
                      <button
                        onClick={() => handleEffortChange(null)}
                        className="block w-full text-left px-4 py-2 text-xs text-gray-600 hover:bg-gray-100/50 transition-colors border-t border-gray-200"
                      >
                        Clear effort
                      </button>
                    )}
                  </div>
                )}
              </div>

              {/* Impact */}
              <div className="relative">
                <button
                  onClick={() => setShowImpactMenu(!showImpactMenu)}
                  className="text-xs text-gray-600 hover:text-gray-900"
                >
                  {task.impact ? (
                    <span className={`px-2 py-1 rounded-md text-xs ${IMPACT_LEVELS[task.impact].color}`}>
                      {IMPACT_LEVELS[task.impact].label}
                    </span>
                  ) : (
                    <span className="px-2 py-1 rounded-md text-xs bg-gray-100 border border-gray-200">
                      Set impact
                    </span>
                  )}
                </button>

                {showImpactMenu && (
                  <div className="absolute bottom-full left-0 mb-1 w-32 bg-white/80 backdrop-blur-lg border border-gray-200 rounded-md shadow-lg z-10">
                    {Object.entries(IMPACT_LEVELS).map(([key, { label, color }]) => (
                      <button
                        key={key}
                        onClick={() => handleImpactChange(key)}
                        className={`block w-full text-left px-4 py-2 text-xs hover:bg-gray-100/50 transition-colors ${
                          task.impact === key ? 'font-bold' : ''
                        }`}
                      >
                        <span className={`px-2 py-1 rounded-md ${color}`}>
                          {label}
                        </span>
                      </button>
                    ))}
                    {task.impact && (
                      <button
                        onClick={() => handleImpactChange(null)}
                        className="block w-full text-left px-4 py-2 text-xs text-gray-600 hover:bg-gray-100/50 transition-colors border-t border-gray-200"
                      >
                        Clear impact
                      </button>
                    )}
                  </div>
                )}
              </div>

              {/* Due Date - moved to right side */}
              <div className="absolute right-2 px-2 pt-1 text-xs text-gray-600">
                {task.dueDate ? (
                  <button 
                    onClick={() => setShowDueDateModal(true)}
                    className="hover:text-gray-800"
                  >
                    Due: {task.dueDate}
                  </button>
                ) : (
                  <button 
                    onClick={() => setShowDueDateModal(true)}
                    className="flex items-center gap-1 hover:text-gray-800"
                  >
                    No due date <span className="font-medium text-blue-500">+</span>
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* Note Modal */}
          {showNoteModal && (
            <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50">
              <div className="bg-white/80 backdrop-blur-lg rounded-lg p-6 w-full max-w-md mx-4">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold text-gray-800">Task Note</h3>
                  <button 
                    onClick={() => setShowNoteModal(false)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    ×
                  </button>
                </div>
                <textarea
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg bg-white/50 focus:outline-none focus:ring-1 focus:ring-teal-500"
                  rows={4}
                  placeholder="Add a note..."
                />

                {/* Note Replies Section */}
                <div className="mt-4">
                  <div className="space-y-3">
                    {noteReplies.map((reply, index) => (
                      <div 
                        key={index}
                        data-reply-index={index}
                        className="bg-gray-50/80 p-3 rounded-lg"
                      >
                        <div className="flex justify-between items-start">
                          <div>
                            <span className="text-sm font-medium">{reply.author}</span>
                            <p className="text-sm text-gray-600 mt-1">{reply.content}</p>
                          </div>
                          <button
                            onClick={() => handleDeleteReply(index)}
                            className="text-gray-400 hover:text-red-500"
                          >
                            ×
                          </button>
                        </div>
                        <div className="text-xs text-gray-400 mt-1">
                          {new Date(reply.timestamp).toLocaleString()}
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Add Reply Form */}
                  <div className="mt-4 flex gap-2">
                    <input
                      type="text"
                      value={newReply}
                      onChange={(e) => setNewReply(e.target.value)}
                      placeholder="Add a reply..."
                      className="flex-1 px-3 py-2 text-sm border border-gray-300 rounded-lg bg-white/50 focus:outline-none focus:ring-1 focus:ring-teal-500"
                    />
                    <button
                      onClick={handleAddReply}
                      className="px-4 py-2 text-sm bg-teal-600 text-white rounded-md hover:bg-teal-700"
                    >
                      Reply
                    </button>
                  </div>
                </div>

                <div className="flex justify-end gap-2 mt-4">
                  <button
                    onClick={() => setShowNoteModal(false)}
                    className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleNoteSave}
                    className="px-4 py-2 text-sm bg-teal-600 text-white rounded-md hover:bg-teal-700"
                  >
                    Save Note
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Due Date Modal */}
          {showDueDateModal && (
            <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50">
              <div className="bg-white/80 backdrop-blur-lg rounded-lg p-6 w-full max-w-sm mx-4 border border-gray-200 shadow-xl">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold text-gray-800">Set Due Date</h3>
                  <button 
                    onClick={() => setShowDueDateModal(false)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    ×
                  </button>
                </div>
                <div className="mb-4">
                  <input
                    type="date"
                    value={newDueDate}
                    onChange={(e) => setNewDueDate(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-200 rounded-lg bg-white/50 backdrop-blur-sm focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400"
                  />
                </div>
                <div className="flex justify-end space-x-2">
                  <button
                    onClick={() => setShowDueDateModal(false)}
                    className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800 transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleDueDateSubmit}
                    className="px-4 py-2 text-sm bg-gray-100 text-gray-800 rounded-md hover:bg-gray-200 transition-colors"
                  >
                    Set Date
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Draggable>
  );
} 