import ReactGA4 from 'react-ga4';

// Initialize GA4 with your measurement ID
export const initGA = () => {
	const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

	if (!measurementId) {
		console.warn('GA4 Measurement ID not found');
		return;
	}

	try {
		ReactGA4.initialize(measurementId);
	} catch (error) {
		console.warn('Failed to initialize GA4:', error);
	}
};

// Track page views
export const logPageView = () => {
	try {
		ReactGA4.send({
			hitType: 'pageview',
			page: window.location.pathname + window.location.search,
		});
	} catch (error) {
		console.warn('Failed to log page view:', error);
	}
};

// Track events
export const logEvent = (category, action, label) => {
	try {
		ReactGA4.event({
			category,
			action,
			label,
		});
	} catch (error) {
		console.warn('Failed to log event:', error);
	}
};
