import {
	doc,
	setDoc,
	getDoc,
	collection,
	query,
	where,
	getDocs,
	addDoc,
	updateDoc,
	arrayUnion,
	writeBatch,
	increment,
	serverTimestamp,
} from 'firebase/firestore';
import { db } from './firebase';
import { auth } from './firebase';
import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase';

export const userService = {
	async createUserProfile(userId, data) {
		console.log('Creating user profile in Firestore:', { userId, data });
		try {
			const userRef = doc(db, 'users', userId);
			await setDoc(userRef, {
				email: data.email,
				username: data.username,
				teams: [],
				createdAt: new Date(),
			});
			console.log('User profile created successfully');

			// Verify the document was created
			const verifyDoc = await getDoc(userRef);
			console.log('Verification of created document:', verifyDoc.data());
		} catch (error) {
			console.error('Error in createUserProfile:', error);
			throw error;
		}
	},

	async updateUserProfile(userId, data) {
		try {
			// Filter out any undefined values
			const cleanData = {};
			Object.keys(data).forEach((key) => {
				if (data[key] !== undefined) {
					cleanData[key] = data[key];
				}
			});

			const userRef = doc(db, 'users', userId);
			await updateDoc(userRef, cleanData);
		} catch (error) {
			console.error('Error updating user profile:', error);
			throw error;
		}
	},

	async getUserProfile(userId) {
		const userRef = doc(db, 'users', userId);
		const userSnap = await getDoc(userRef);
		return userSnap.exists()
			? { id: userSnap.id, ...userSnap.data() }
			: null;
	},

	async createTeam(name, ownerId) {
		try {
			// Create a new team document with default columns
			const teamsRef = collection(db, 'teams');
			const teamData = {
				name,
				ownerId,
				members: [ownerId],
				columns: [
					{ id: 'todo', name: 'To Do' },
					{ id: 'inprogress', name: 'In Progress' },
					{ id: 'done', name: 'Done' },
				],
				createdAt: serverTimestamp(),
			};

			// Create the team
			const teamDoc = await addDoc(teamsRef, teamData);

			// Create team membership document for owner
			await setDoc(doc(db, 'teamMembers', `${teamDoc.id}_${ownerId}`), {
				userId: ownerId,
				teamId: teamDoc.id,
				role: 'owner',
				joinedAt: serverTimestamp(),
				active: true,
			});

			// Return the new team data with its ID
			return {
				id: teamDoc.id,
				...teamData,
			};
		} catch (error) {
			console.error('Error creating team:', error);
			throw error;
		}
	},

	async updateTeamColumns(teamId, columns) {
		const teamRef = doc(db, 'teams', teamId);
		await updateDoc(teamRef, { columns });
	},

	async getTeamMembers(teamId) {
		const teamRef = doc(db, 'teams', teamId);
		const teamSnap = await getDoc(teamRef);
		if (!teamSnap.exists()) return [];

		const memberIds = teamSnap.data().members;
		const members = [];

		for (const memberId of memberIds) {
			const memberProfile = await this.getUserProfile(memberId);
			if (memberProfile) {
				members.push(memberProfile);
			}
		}

		return members;
	},

	async addTeamMember(teamId, email) {
		try {
			// Create invite document
			const inviteRef = await addDoc(collection(db, 'teamInvites'), {
				teamId,
				email: email.toLowerCase(),
				status: 'pending',
				createdAt: serverTimestamp(),
			});

			const teamDoc = await getDoc(doc(db, 'teams', teamId));
			const inviterDoc = await getDoc(
				doc(db, 'users', auth.currentUser.uid)
			);

			// Send email using Netlify function
			const response = await fetch(
				'/.netlify/functions/team-invitation',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						to: email.toLowerCase(),
						parameters: {
							team_name: teamDoc.data().name,
							inviter_name:
								inviterDoc.data().username ||
								inviterDoc.data().email,
							accept_url: `${window.location.origin}/accept-invite?id=${inviteRef.id}`,
						},
					}),
				}
			);

			if (!response.ok) {
				throw new Error('Failed to send invitation email');
			}

			return true;
		} catch (error) {
			console.error('Error adding team member:', error);
			throw error;
		}
	},

	async getAllUsers() {
		const usersRef = collection(db, 'users');
		const snapshot = await getDocs(usersRef);
		return snapshot.docs.map((doc) => ({
			id: doc.id,
			...doc.data(),
		}));
	},

	async getUserTeams(userId) {
		try {
			console.log('Getting teams for user:', userId);

			// First get the user's team IDs
			const userRef = doc(db, 'users', userId);
			const userSnap = await getDoc(userRef);
			if (!userSnap.exists()) {
				console.log('No user document found');
				return [];
			}

			const userData = userSnap.data();
			const teamIds = userData.teams || [];
			console.log('Team IDs from user document:', teamIds);

			// Then get the actual team data
			const teams = [];
			for (const teamId of teamIds) {
				const teamRef = doc(db, 'teams', teamId);
				const teamSnap = await getDoc(teamRef);
				if (teamSnap.exists()) {
					teams.push({ id: teamSnap.id, ...teamSnap.data() });
				} else {
					console.log('Team not found:', teamId);
				}
			}

			console.log('Final teams list:', teams);
			return teams;
		} catch (error) {
			console.error('Error getting user teams:', error);
			return [];
		}
	},

	async deleteTeam(teamId) {
		try {
			const batch = writeBatch(db);

			// Delete the team document
			const teamRef = doc(db, 'teams', teamId);
			batch.delete(teamRef);

			// Delete all team member documents
			const teamMembersQuery = query(
				collection(db, 'teamMembers'),
				where('teamId', '==', teamId)
			);
			const teamMembersSnapshot = await getDocs(teamMembersQuery);
			teamMembersSnapshot.forEach((doc) => {
				batch.delete(doc.ref);
			});

			// Delete all tasks associated with the team
			const tasksQuery = query(
				collection(db, 'tasks'),
				where('teamId', '==', teamId)
			);
			const tasksSnapshot = await getDocs(tasksQuery);
			tasksSnapshot.forEach((doc) => {
				batch.delete(doc.ref);
			});

			// Remove team from all users' teams arrays
			const teamDoc = await getDoc(teamRef);
			if (teamDoc.exists()) {
				const members = teamDoc.data().members || [];
				for (const memberId of members) {
					const userRef = doc(db, 'users', memberId);
					const userDoc = await getDoc(userRef);
					if (userDoc.exists()) {
						const userData = userDoc.data();
						const updatedTeams = (userData.teams || []).filter(
							(id) => id !== teamId
						);
						batch.update(userRef, { teams: updatedTeams });
					}
				}
			}

			// Commit all the changes
			await batch.commit();
			return true;
		} catch (error) {
			console.error('Error deleting team:', error);
			throw error;
		}
	},

	async removeTeamMember(teamId, memberId) {
		try {
			const batch = writeBatch(db);

			// Remove the team member document
			const teamMemberRef = doc(
				db,
				'teamMembers',
				`${teamId}_${memberId}`
			);
			batch.delete(teamMemberRef);

			// Remove member from team's members array
			const teamRef = doc(db, 'teams', teamId);
			const teamDoc = await getDoc(teamRef);
			if (teamDoc.exists()) {
				const teamData = teamDoc.data();
				batch.update(teamRef, {
					members: teamData.members.filter((id) => id !== memberId),
				});
			}

			// Remove team from user's teams array
			const userRef = doc(db, 'users', memberId);
			const userDoc = await getDoc(userRef);
			if (userDoc.exists()) {
				const userData = userDoc.data();
				batch.update(userRef, {
					teams: (userData.teams || []).filter((id) => id !== teamId),
				});
			}

			// Commit all changes
			await batch.commit();
			return true;
		} catch (error) {
			console.error('Error removing team member:', error);
			throw error;
		}
	},

	// Add this method to check for pending invitations when a user signs up
	async checkPendingInvitations(userEmail) {
		const invitationsRef = collection(db, 'invitations');
		const q = query(
			invitationsRef,
			where('email', '==', userEmail),
			where('status', '==', 'pending')
		);
		const snapshot = await getDocs(q);

		const batch = writeBatch(db);

		for (const doc of snapshot.docs) {
			const invitation = doc.data();
			const teamRef = doc(db, 'teams', invitation.teamId);
			const teamSnap = await getDoc(teamRef);

			if (teamSnap.exists()) {
				const teamData = teamSnap.data();
				// Update team members
				batch.update(teamRef, {
					members: [...teamData.members, userEmail],
				});
				// Mark invitation as accepted
				batch.update(doc.ref, { status: 'accepted' });
			}
		}

		await batch.commit();
	},

	async getProjectMembers(projectId) {
		try {
			if (!projectId) {
				console.log('No projectId provided to getProjectMembers');
				return [];
			}

			console.log('Getting members for project:', projectId);

			// First get the team document to get all member IDs
			const teamRef = doc(db, 'teams', projectId);
			const teamDoc = await getDoc(teamRef);

			if (!teamDoc.exists()) {
				console.log('Team not found:', projectId);
				return [];
			}

			const memberIds = teamDoc.data().members || [];
			console.log('Member IDs from team document:', memberIds);

			// Get user documents for all members
			const memberPromises = memberIds.map(async (userId) => {
				const userRef = doc(db, 'users', userId);
				const userDoc = await getDoc(userRef);

				if (!userDoc.exists()) {
					console.log('No user document found for userId:', userId);
					return null;
				}

				const userData = userDoc.data();

				// Get role from teamMembers collection
				const teamMemberRef = doc(
					db,
					'teamMembers',
					`${projectId}_${userId}`
				);
				const teamMemberDoc = await getDoc(teamMemberRef);
				const role = teamMemberDoc.exists()
					? teamMemberDoc.data().role
					: 'member';

				return {
					id: userDoc.id,
					email: userData.email || '',
					username: userData.username || '',
					role: role,
				};
			});

			const members = await Promise.all(memberPromises);
			const filteredMembers = members.filter((member) => member !== null);
			console.log(
				'Final members list for team:',
				projectId,
				filteredMembers
			);

			return filteredMembers;
		} catch (error) {
			console.error('Error getting project members:', error);
			return [];
		}
	},

	async acceptTeamInvitation(inviteId, userId) {
		try {
			// Get the invite document
			const inviteDoc = await getDoc(doc(db, 'teamInvites', inviteId));
			if (!inviteDoc.exists()) {
				throw new Error('Invitation not found');
			}

			const inviteData = inviteDoc.data();
			const teamId = inviteData.teamId;

			// Get the team document
			const teamDoc = await getDoc(doc(db, 'teams', teamId));
			if (!teamDoc.exists()) {
				throw new Error('Team not found');
			}

			const batch = writeBatch(db);

			// Add user to team members
			const teamRef = doc(db, 'teams', teamId);
			batch.update(teamRef, {
				members: arrayUnion(userId),
			});

			// Add team to user's teams
			const userRef = doc(db, 'users', userId);
			batch.update(userRef, {
				teams: arrayUnion(teamId),
			});

			// Create team membership document
			const membershipRef = doc(db, 'teamMembers', `${teamId}_${userId}`);
			batch.set(membershipRef, {
				userId,
				teamId,
				role: 'member',
				joinedAt: serverTimestamp(),
				active: true,
			});

			// Delete the invitation
			const inviteRef = doc(db, 'teamInvites', inviteId);
			batch.delete(inviteRef);

			await batch.commit();

			return teamId;
		} catch (error) {
			console.error('Error accepting invitation:', error);
			throw error;
		}
	},

	async createTask(taskData) {
		try {
			const tasksRef = collection(db, 'tasks'); // Root level collection
			const taskDoc = await addDoc(tasksRef, {
				...taskData,
				createdAt: serverTimestamp(),
			});
			return { id: taskDoc.id, ...taskData };
		} catch (error) {
			console.error('Error creating task:', error);
			throw error;
		}
	},

	async getTeamTasks(teamId) {
		try {
			const q = query(
				collection(db, 'tasks'),
				where('teamId', '==', teamId)
			);
			const snapshot = await getDocs(q);
			return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
		} catch (error) {
			console.error('Error getting team tasks:', error);
			throw error;
		}
	},
};
