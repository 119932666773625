import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { useSearchParams } from 'react-router-dom';
import { userService } from '../services/userService';

export default function Login() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isSignup, setIsSignup] = useState(false);
	const [error, setError] = useState('');
	const [teamId, setTeamId] = useState(null);
	const { login, signup, signInWithGoogle, user } = useAuth();
	const location = useLocation();
	const navigate = useNavigate();
	const searchParams = useSearchParams()[0];

	// Get redirect path from URL parameters
	const redirectTo = searchParams.get('redirect') || '/dashboard';

	useEffect(() => {
		// Get email and teamId from URL parameters
		const params = new URLSearchParams(location.search);
		const inviteEmail = params.get('email');
		const inviteTeamId = params.get('teamId');
		const isInvite = params.get('invite');

		if (inviteEmail && isInvite) {
			setEmail(inviteEmail);
			setTeamId(inviteTeamId);
			setIsSignup(true);
		}
	}, [location]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setError('');

		try {
			let authUser;
			if (isSignup) {
				authUser = await signup(email, password);
			} else {
				authUser = await login(email, password);
			}

			// After successful authentication, check for invitation
			const inviteId = searchParams.get('inviteId');
			if (inviteId) {
				try {
					console.log('Processing invitation after login:', inviteId);
					await userService.acceptTeamInvitation(
						inviteId,
						authUser.user.uid
					);
					const teamId = searchParams.get('teamId');
					console.log('Redirecting to team:', teamId);
					navigate(`/dashboard?teamId=${teamId}`, { replace: true });
				} catch (error) {
					console.error('Error accepting invitation:', error);
					setError(
						'Failed to accept team invitation. Please try again.'
					);
				}
			} else {
				navigate(redirectTo, { replace: true });
			}
		} catch (error) {
			console.error('Auth error:', error);
			setError(error.message);
		}
	};

	const handleGoogleSignIn = async () => {
		try {
			await signInWithGoogle();

			// After successful login, check for pending payment
			const pendingAmount = localStorage.getItem('pendingPaymentAmount');
			if (pendingAmount && redirectTo === '/contribute') {
				localStorage.removeItem('pendingPaymentAmount'); // Clean up
			}

			// Redirect to the specified path
			navigate(redirectTo);
		} catch (error) {
			console.error('Login error:', error);
			setError(error.message);
		}
	};

	return (
		<div className='min-h-screen bg-gradient-to-br from-teal-50 via-emerald-50 to-cyan-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
			<div className='max-w-md w-full space-y-8 bg-white/70 backdrop-blur-lg p-8 rounded-2xl shadow-xl'>
				<div>
					<h1 className='text-center text-3xl font-bold bg-gradient-to-r from-teal-600 to-blue-600 bg-clip-text text-transparent'>
						Product Sketch
					</h1>
					<h2 className='mt-6 text-center text-2xl font-semibold text-gray-900'>
						{isSignup ? 'Create an account' : 'Welcome back'}
					</h2>
					{email && isSignup && (
						<p className='mt-2 text-center text-sm text-gray-600'>
							Sign up to join your team
						</p>
					)}
					{error && (
						<p className='mt-2 text-center text-sm text-red-600 bg-red-50 py-2 px-4 rounded-lg'>
							{error}
						</p>
					)}
				</div>

				<form
					className='mt-8 space-y-4'
					onSubmit={handleSubmit}
				>
					<div className='space-y-4'>
						<div>
							<input
								type='email'
								required
								className='appearance-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-400 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-shadow hover:shadow-md'
								placeholder='Email address'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								readOnly={!!location.search}
							/>
						</div>
						<div>
							<input
								type='password'
								required
								className='appearance-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-400 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-shadow hover:shadow-md'
								placeholder='Password'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
					</div>

					<div className='pt-4'>
						<button
							type='submit'
							className='group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 transition-all shadow-sm hover:shadow-md'
						>
							{isSignup ? 'Create account' : 'Sign in'}
						</button>
					</div>
				</form>

				<div className='relative py-4'>
					<div className='absolute inset-0 flex items-center'>
						<div className='w-full border-t border-gray-300'></div>
					</div>
					<div className='relative flex justify-center text-sm'>
						<span className='px-2 bg-white/70 text-gray-500'>
							Or continue with
						</span>
					</div>
				</div>

				<div>
					<button
						onClick={handleGoogleSignIn}
						className='w-full flex items-center justify-center gap-3 py-3 px-4 rounded-lg border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 transition-all shadow-sm hover:shadow-md'
					>
						<FontAwesomeIcon
							icon={faGoogle}
							className='h-5 w-5 text-red-500'
						/>
						Sign in with Google
					</button>
				</div>

				{!location.search && (
					<div className='text-sm text-center'>
						<button
							onClick={() => setIsSignup(!isSignup)}
							className='font-medium text-teal-600 hover:text-teal-700'
						>
							{isSignup
								? 'Already have an account? Sign in'
								: 'Need an account? Sign up'}
						</button>
					</div>
				)}
			</div>
		</div>
	);
}
