import { useState } from 'react';
import { userService } from '../services/userService';

export default function MarkdownImporter({ teamId, onImportComplete }) {
	const [markdown, setMarkdown] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [preview, setPreview] = useState([]);
	const [success, setSuccess] = useState('');

	const parseMarkdown = (text) => {
		const lines = text.split('\n');
		const validTasks = [];
		const invalidLines = [];

		lines.forEach((line, index) => {
			if (line.trim()) {
				// Skip empty lines
				if (/^[-*]\s*\[\s*\]/.test(line)) {
					validTasks.push({
						text: line.replace(/^[-*]\s*\[\s*\]/, '').trim(),
						lineNumber: index + 1,
					});
				} else {
					invalidLines.push({
						text: line,
						lineNumber: index + 1,
					});
				}
			}
		});

		return { validTasks, invalidLines };
	};

	const handlePreview = () => {
		setError('');
		const { validTasks, invalidLines } = parseMarkdown(markdown);

		if (validTasks.length === 0) {
			setError('No valid tasks found. Tasks should be formatted as:');
			setPreview([]);
			return;
		}

		if (invalidLines.length > 0) {
			setError(
				`Found ${invalidLines.length} invalid line(s). Each task should start with "- [ ]" or "* [ ]"`
			);
		}

		setPreview(validTasks);
	};

	const handleImport = async () => {
		setLoading(true);
		setError('');
		setSuccess('');
		try {
			const { validTasks, invalidLines } = parseMarkdown(markdown);

			if (validTasks.length === 0) {
				setError('No valid tasks found. Tasks should be formatted as:');
				return;
			}

			// Get existing tasks for this team
			const existingTasks = await userService.getTeamTasks(teamId);
			const existingTitles = new Set(
				existingTasks.map((t) => t.title.toLowerCase().trim())
			);

			// Filter out duplicates
			const newTasks = validTasks.filter(
				(task) => !existingTitles.has(task.text.toLowerCase().trim())
			);
			const duplicateCount = validTasks.length - newTasks.length;

			// Create tasks in the 'todo' column
			for (const task of newTasks) {
				await userService.createTask({
					teamId,
					title: task.text,
					status: 'todo',
					createdAt: new Date(),
				});
			}

			setMarkdown('');
			setPreview([]);

			if (duplicateCount > 0) {
				setError(
					`Imported ${
						newTasks.length
					} tasks. Skipped ${duplicateCount} duplicate task(s)${
						invalidLines.length
							? ` and ${invalidLines.length} invalid line(s)`
							: ''
					}.`
				);
			} else if (invalidLines.length > 0) {
				setError(
					`Imported ${newTasks.length} tasks. Skipped ${invalidLines.length} invalid line(s).`
				);
			} else {
				setSuccess(`Successfully imported ${newTasks.length} tasks!`);
				setTimeout(() => {
					onImportComplete?.();
				}, 500);
			}
		} catch (error) {
			console.error('Error importing tasks:', error);
			setError('Failed to import tasks. Please try again.');
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className='space-y-4'>
			{error && (
				<div className='p-4 bg-red-50 border border-red-200 rounded-lg'>
					<p className='text-red-600 text-sm'>{error}</p>
					{error.includes('should be formatted') && (
						<div className='mt-2 p-3 bg-gray-50 rounded text-sm font-mono'>
							- [ ] Task one
							<br />
							- [ ] Task two
							<br />* [ ] Or use asterisk
						</div>
					)}
				</div>
			)}

			{success && (
				<div className='p-4 bg-green-50 border border-green-200 rounded-lg'>
					<p className='text-green-600 text-sm flex items-center gap-2'>
						<svg
							className='w-4 h-4'
							fill='none'
							stroke='currentColor'
							viewBox='0 0 24 24'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='M5 13l4 4L19 7'
							/>
						</svg>
						{success}
					</p>
				</div>
			)}

			<div>
				<textarea
					value={markdown}
					onChange={(e) => {
						setMarkdown(e.target.value);
						setError('');
						setPreview([]);
					}}
					placeholder='Paste your markdown tasks here:&#10;- [ ] Task 1&#10;- [ ] Task 2'
					className='w-full h-32 p-2 border rounded mb-2 font-mono text-sm'
				/>

				<button
					onClick={handlePreview}
					className='text-sm text-teal-600 hover:text-teal-700'
				>
					Preview
				</button>
			</div>

			{preview.length > 0 && (
				<div className='p-4 bg-gray-50 rounded-lg'>
					<h4 className='text-sm font-medium text-gray-700 mb-2'>
						Preview:
					</h4>
					<ul className='space-y-1'>
						{preview.map((task, index) => (
							<li
								key={index}
								className='text-sm text-gray-600'
							>
								• {task.text}
							</li>
						))}
					</ul>
				</div>
			)}

			<div className='flex justify-end'>
				<button
					onClick={handleImport}
					disabled={loading || !markdown.trim()}
					className='px-4 py-2 bg-teal-600 text-white rounded hover:bg-teal-700 disabled:opacity-50 disabled:cursor-not-allowed'
				>
					{loading ? 'Importing...' : 'Import Tasks'}
				</button>
			</div>
		</div>
	);
}
