import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../services/firebase';
import { userService } from '../services/userService';

export default function AcceptInvite() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const inviteId = searchParams.get('id');

  useEffect(() => {
    const handleInvite = async () => {
      if (!inviteId) {
        console.log('No invite ID found');
        navigate('/');
        return;
      }

      try {
        console.log('Fetching invite document:', inviteId);
        const inviteRef = doc(db, 'teamInvites', inviteId);
        const inviteDoc = await getDoc(inviteRef);
        
        if (!inviteDoc.exists()) {
          console.log('Invite document not found');
          setError('Invalid or expired invitation');
          navigate('/');
          return;
        }

        const inviteData = inviteDoc.data();
        console.log('Invite data:', inviteData);

        if (!inviteData.email || !inviteData.teamId) {
          console.log('Invalid invite data:', inviteData);
          setError('Invalid invitation data');
          navigate('/');
          return;
        }

        if (user) {
          console.log('User is logged in, accepting invitation');
          try {
            await userService.acceptTeamInvitation(inviteId, user.uid);
            navigate(`/dashboard?teamId=${inviteData.teamId}`);
          } catch (error) {
            console.error('Error accepting invitation:', error);
            setError('Failed to accept invitation. Please try again.');
          }
        } else {
          console.log('User not logged in, redirecting to login');
          const encodedEmail = encodeURIComponent(inviteData.email);
          const loginUrl = `/login?invite=true&email=${encodedEmail}&teamId=${inviteData.teamId}&inviteId=${inviteId}`;
          console.log('Redirecting to:', loginUrl);
          navigate(loginUrl);
        }
      } catch (error) {
        console.error('Error handling invite:', error);
        setError(error.message || 'Failed to process invitation');
        // Don't navigate away immediately on error
      } finally {
        setLoading(false);
      }
    };

    handleInvite();
  }, [inviteId, user, navigate]);

  if (loading) {
    return (
      <div className='h-screen bg-gradient-to-br from-teal-50 via-emerald-50 to-cyan-100 flex items-center justify-center'>
        <div className='relative flex flex-col items-center'>
          <div className='w-16 h-16 border-4 border-teal-600 border-t-transparent rounded-full animate-spin'></div>
          <div className='absolute top-0 w-16 h-16 border-4 border-teal-200 rounded-full animate-pulse'></div>
          <div className='mt-6 text-teal-600 font-medium'>
            Processing invitation...
          </div>
          {error && (
            <div className='mt-4 text-red-600 text-sm bg-red-50 px-4 py-2 rounded-lg'>
              {error}
            </div>
          )}
        </div>
      </div>
    );
  }

  return null;
} 