import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheckCircle,
	faClock,
	faRocket,
	faArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useState, useEffect } from 'react';
import { db } from '../services/firebase';
import { doc, updateDoc, getDoc, setDoc, increment } from 'firebase/firestore';

export default function Roadmap() {
	const { user, userProfile } = useAuth();
	const [userVotes, setUserVotes] = useState({});
	const [featureVotes, setFeatureVotes] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const roadmapItems = [
		{
			status: 'inProgress',
			title: 'Under Development',
			description: 'Features currently being developed and tested',
			items: [
				{
					id: 'notifications-enhancements',
					text: 'Improve team notifications',
				},
				{
					id: 'notifications-settings',
					text: 'Add granular notification settings',
				},
				{
					id: 'better-onboarding',
					text: 'Improve onboarding experience',
				},
				{
					id: 'archiving-tasks',
					text: 'Add ability to archive tasks',
				},
			],
		},
		{
			status: 'planned',
			title: 'Coming Soon',
			description: 'Features planned for upcoming releases',
			items: [
				{
					id: 'public-boards',
					text: 'Add option to make boards public',
				},
				{
					id: 'custom-fields',
					text: 'Add custom fields to tasks',
				},
				{
					id: 'time-tracking',
					text: 'Time estimation and time tracking',
				},
				{
					id: 'analytics',
					text: 'Simple reporting and analytics',
				},
			],
		},
		{
			status: 'completed',
			title: 'Completed',
			description: 'Features recently released',
			items: [
				{
					id: 'markdown-import',
					text: 'Import tasks from markdown',
				},
				{
					id: 'drag-drop',
					text: 'Drag and drop interface',
				},
				{
					id: 'team-collab',
					text: 'Team collaboration',
				},
				{
					id: 'export',
					text: 'Export to markdown',
				},
				{
					id: 'matrix',
					text: 'Effort/impact matrix view',
				},
				{
					id: 'mobile',
					text: 'Mobile-responsive design',
				},
			],
		},
	];

	useEffect(() => {
		const fetchVotes = async () => {
			try {
				// Get feature votes
				const votesDoc = await getDoc(
					doc(db, 'featureVotes', 'counts')
				);
				setFeatureVotes(votesDoc.data() || {});

				// Get user's votes if logged in
				if (user) {
					const userVotesDoc = await getDoc(
						doc(db, 'userVotes', user.uid)
					);
					setUserVotes(userVotesDoc.data() || {});
				}
			} catch (error) {
				// console.error('Error fetching votes:', error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchVotes();
	}, [user]);

	const handleVote = async (featureId) => {
		if (!user) {
			return;
		}

		try {
			const hasVoted = userVotes[featureId];
			const votesRef = doc(db, 'featureVotes', 'counts');
			const userVotesRef = doc(db, 'userVotes', user.uid);

			// console.log('Current votes:', featureVotes[featureId]);
			// console.log('Has voted:', hasVoted);

			if (hasVoted) {
				// Remove vote
				await updateDoc(votesRef, {
					[featureId]: increment(-1),
				});
				await updateDoc(userVotesRef, {
					[featureId]: false,
				});
				setUserVotes((prev) => ({
					...prev,
					[featureId]: false,
				}));
				setFeatureVotes((prev) => ({
					...prev,
					[featureId]: Math.max(0, (prev[featureId] || 0) - 1), // Prevent negative votes
				}));
			} else {
				// Add vote
				await setDoc(
					votesRef,
					{
						[featureId]: increment(1),
					},
					{ merge: true }
				);
				await setDoc(
					userVotesRef,
					{
						[featureId]: true,
					},
					{ merge: true }
				);
				setUserVotes((prev) => ({
					...prev,
					[featureId]: true,
				}));
				setFeatureVotes((prev) => ({
					...prev,
					[featureId]: (prev[featureId] || 0) + 1,
				}));
			}

			// console.log('Updated votes:', featureVotes[featureId]);
		} catch (error) {
			// console.error('Error updating vote:', error);
		}
	};

	const getStatusIcon = (status) => {
		switch (status) {
			case 'completed':
				return (
					<FontAwesomeIcon
						icon={faCheckCircle}
						className='text-green-500'
					/>
				);
			case 'inProgress':
				return (
					<FontAwesomeIcon
						icon={faClock}
						className='text-yellow-500'
					/>
				);
			case 'planned':
				return (
					<FontAwesomeIcon
						icon={faRocket}
						className='text-blue-500'
					/>
				);
			default:
				return null;
		}
	};

	const VoteButton = ({ voted, votes, onClick, disabled }) => (
		<button
			onClick={onClick}
			disabled={disabled}
			className={`px-2 py-1 rounded-md flex items-center gap-1.5 transition-colors ${
				voted
					? 'bg-teal-100 text-teal-700 hover:bg-teal-200'
					: 'bg-gray-100 text-gray-600 hover:bg-gray-200'
			}`}
		>
			<FontAwesomeIcon
				icon={faArrowUp}
				className={`w-3 h-3 ${voted ? 'text-teal-600' : ''}`}
			/>
			<span className='font-medium'>{votes || 0}</span>
		</button>
	);

	return (
		<div className='min-h-screen bg-gradient-to-br from-teal-50 via-emerald-50 to-cyan-100 py-12'>
			<div className='max-w-6xl mx-auto px-4'>
				<div className='bg-white/50 backdrop-blur-lg rounded-2xl p-8 shadow-xl border border-white/20'>
					<h1 className='text-3xl font-bold text-gray-900 mb-2'>
						Product Roadmap
					</h1>
					<p className='text-gray-600 mb-4'>
						We believe in building in public and keeping our users
						informed about what's coming next.
					</p>
					{!user ? (
						<div className='mb-8 p-4 bg-blue-50 rounded-lg text-sm text-blue-700'>
							👋{' '}
							<Link
								to={`/login?redirect=${encodeURIComponent(
									'/roadmap'
								)}`}
								className='font-medium underline'
							>
								Log in
							</Link>{' '}
							to vote on features you'd like to see implemented
							next! Or{' '}
							<Link
								to='/feedback'
								className='font-medium underline'
							>
								submit a feature request
							</Link>
							.
						</div>
					) : (
						<div className='mb-8 p-4 bg-teal-50 rounded-lg text-sm text-teal-700'>
							👋 Hi,{' '}
							{userProfile?.username ||
								user.email?.split('@')[0] ||
								'there'}
							! You are logged in, please vote on new features or{' '}
							{''}
							<Link
								to='/feedback'
								className='font-medium underline'
							>
								submit a feature request
							</Link>
							.
						</div>
					)}

					<div className='space-y-8'>
						{/* Top section: Upcoming and In Progress side by side */}
						<div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
							{/* Planned Features */}
							<div>
								<div className='flex items-center gap-2 mb-4'>
									<FontAwesomeIcon
										icon={faRocket}
										className='text-blue-500'
									/>
									<h2 className='text-xl font-semibold text-gray-900'>
										Vote on Upcoming Features
									</h2>
								</div>
								<div className='bg-white/70 rounded-xl p-6 border border-gray-200/20 h-full'>
									<ul className='space-y-4'>
										{roadmapItems
											.find(
												(section) =>
													section.status === 'planned'
											)
											?.items.map((item) => (
												<li
													key={item.id}
													className='flex items-center gap-3'
												>
													<VoteButton
														voted={
															userVotes[item.id]
														}
														votes={
															featureVotes[
																item.id
															]
														}
														onClick={() =>
															handleVote(item.id)
														}
														disabled={
															isLoading || !user
														}
													/>
													<span className='text-gray-700'>
														{item.text}
													</span>
												</li>
											))}
									</ul>
								</div>
							</div>

							{/* In Progress Features */}
							<div>
								<div className='flex items-center gap-2 mb-4'>
									<FontAwesomeIcon
										icon={faClock}
										className='text-yellow-500'
									/>
									<h2 className='text-xl font-semibold text-gray-900'>
										Currently in Development
									</h2>
								</div>
								<div className='bg-white/70 rounded-xl p-6 border border-gray-200/20 h-full'>
									<ul className='space-y-4'>
										{roadmapItems
											.find(
												(section) =>
													section.status ===
													'inProgress'
											)
											?.items.map((item) => (
												<li
													key={item.id}
													className='flex items-center gap-3'
												>
													<VoteButton
														voted={
															userVotes[item.id]
														}
														votes={
															featureVotes[
																item.id
															]
														}
														onClick={() =>
															handleVote(item.id)
														}
														disabled={
															isLoading || !user
														}
													/>
													<span className='text-gray-700'>
														{item.text}
													</span>
												</li>
											))}
									</ul>
								</div>
							</div>
						</div>

						{/* Bottom section: Recently Completed full width */}
						<div>
							<div className='flex items-center gap-2 mb-4'>
								<FontAwesomeIcon
									icon={faCheckCircle}
									className='text-green-500'
								/>
								<h2 className='text-xl font-semibold text-gray-900'>
									Recently Completed
								</h2>
							</div>
							<div className='bg-white/70 rounded-xl p-6 border border-gray-200/20'>
								<ul className='grid grid-cols-1 md:grid-cols-2 gap-4'>
									{roadmapItems
										.find(
											(section) =>
												section.status === 'completed'
										)
										?.items.map((item) => (
											<li
												key={item.id}
												className='flex items-center gap-3'
											>
												<span className='w-[68px] px-2 py-1 text-xs bg-green-50 text-green-700 rounded-md flex items-center justify-center'>
													Completed
												</span>
												<span className='text-gray-700'>
													{item.text}
												</span>
											</li>
										))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
