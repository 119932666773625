import { Link } from 'react-router-dom';

export default function ThankYou() {
	return (
		<div className='min-h-screen bg-gradient-to-br from-teal-50 via-emerald-50 to-cyan-100 py-20'>
			<div className='max-w-3xl mx-auto px-4'>
				<div className='bg-white/50 backdrop-blur-lg rounded-2xl p-8 shadow-xl border border-white/20 text-center'>
					<div className='text-5xl mb-8'>★</div>
					<h1 className='text-3xl font-bold text-gray-900 mb-6'>
						Thank You for Being a Founding Member!
					</h1>

					<div className='space-y-6 max-w-2xl mx-auto'>
						<p className='text-xl text-gray-600'>
							Your support means the world to us. You're now a
							Founding Member of Product Sketch, and we're
							incredibly grateful to have you on this journey.
						</p>

						<div className='bg-teal-50 rounded-xl p-6 border border-teal-100 text-left'>
							<h2 className='font-semibold text-teal-800 mb-3'>
								What this means:
							</h2>
							<ul className='space-y-3 text-teal-600'>
								<li className='flex items-start gap-2'>
									<span className='text-teal-500 mt-1'>
										✓
									</span>
									<span>
										Lifetime access to Product Sketch ($10+
										contributors)
									</span>
								</li>
								<li className='flex items-start gap-2'>
									<span className='text-teal-500 mt-1'>
										✓
									</span>
									<span>
										Recognition as a Founding Member
									</span>
								</li>
								<li className='flex items-start gap-2'>
									<span className='text-teal-500 mt-1'>
										✓
									</span>
									<span>Early access to new features</span>
								</li>
								<li className='flex items-start gap-2'>
									<span className='text-teal-500 mt-1'>
										✓
									</span>
									<span>
										The satisfaction of contributing to an
										indie project
									</span>
								</li>
							</ul>
						</div>

						<p className='text-gray-600'>
							Your support will help us build and improve Product
							Sketch, making it even better for everyone. We're
							committed to creating something truly valuable, and
							you're now part of that story.
						</p>

						<div className='pt-6'>
							<Link
								to='/login'
								className='inline-block px-8 py-4 bg-teal-600 text-white rounded-lg hover:bg-teal-700 transition-colors shadow-lg hover:shadow-xl'
							>
								Log In to Get Started
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
