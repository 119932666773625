import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default function Footer() {
	return (
		<footer className='bg-white/70 backdrop-blur-lg border-t border-gray-200/20'>
			<div className='max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8'>
				<div className='grid grid-cols-1 md:flex md:justify-between gap-8'>
					{/* Product */}
					<div>
						<h3 className='text-xs font-semibold text-gray-500 tracking-wider uppercase mb-3'>
							Product
						</h3>
						<div className='space-y-2'>
							<Link
								to='/'
								className='text-sm text-gray-600 hover:text-gray-900 block'
							>
								Home
							</Link>
							<Link
								to='/contribute'
								className='text-sm text-gray-600 hover:text-gray-900 block'
							>
								Contribute
							</Link>
							<Link
								to='/roadmap'
								className='text-sm text-gray-600 hover:text-gray-900 block'
							>
								Roadmap
							</Link>
						</div>
					</div>

					{/* Support */}
					<div>
						<h3 className='text-xs font-semibold text-gray-500 tracking-wider uppercase mb-3'>
							Support
						</h3>
						<div className='space-y-2'>
							<a
								href='mailto:admin@productsketch.com'
								className='text-sm text-gray-600 hover:text-gray-900 flex items-center gap-2'
							>
								<FontAwesomeIcon
									icon={faEnvelope}
									className='h-3 w-3'
								/>
								admin@productsketch.com
							</a>
						</div>
					</div>

					{/* Legal */}
					{/* <div>
						<h3 className='text-xs font-semibold text-gray-500 tracking-wider uppercase mb-3'>
							Legal
						</h3>
						<div className='space-y-2'>
							<Link
								to='/privacy'
								className='text-sm text-gray-600 hover:text-gray-900 block'
							>
								Privacy Policy
							</Link>
							<Link
								to='/terms'
								className='text-sm text-gray-600 hover:text-gray-900 block'
							>
								Terms of Service
							</Link>
						</div>
					</div> */}
				</div>
			</div>
		</footer>
	);
}
