import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

export default function Feedback() {
	const { user } = useAuth();
	const [message, setMessage] = useState('');
	const [email, setEmail] = useState('');
	const [submitted, setSubmitted] = useState(false);

	// Set email if user is logged in
	useEffect(() => {
		if (user?.email) {
			setEmail(user.email);
		}
	}, [user]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await fetch('/.netlify/functions/submit-feedback', {
				method: 'POST',
				body: JSON.stringify({
					message,
					email,
					type: 'feature_request',
				}),
			});
			setSubmitted(true);
			setMessage('');
			setEmail('');
		} catch (error) {
			console.error('Error submitting feedback:', error);
		}
	};

	return (
		<div className='min-h-screen bg-gradient-to-br from-teal-50 via-emerald-50 to-cyan-100 py-12'>
			<div className='max-w-2xl mx-auto px-4'>
				<div className='bg-white/50 backdrop-blur-lg rounded-2xl p-8 shadow-xl border border-white/20'>
					<h1 className='text-3xl font-bold text-gray-900 mb-2'>
						Feature Request
					</h1>
					<p className='text-gray-600 mb-8'>
						We'd love to hear your ideas for improving Product
						Sketch.
					</p>

					{submitted ? (
						<div className='text-left py-8'>
							<h2 className='text-xl font-semibold text-gray-900 mb-2'>
								Thank you for your feedback!
							</h2>
							<p className='text-gray-600'>
								We'll review your suggestion and contact you if
								we need more information.
							</p>
						</div>
					) : (
						<form
							onSubmit={handleSubmit}
							className='space-y-6'
						>
							<div>
								<label
									htmlFor='message'
									className='block text-sm font-medium text-gray-700 mb-2'
								>
									What feature would you like to see?
								</label>
								<textarea
									id='message'
									rows={4}
									value={message}
									onChange={(e) => setMessage(e.target.value)}
									className='w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent'
									placeholder='Describe the feature you would like us to add...'
									required
								/>
							</div>
							<div>
								<label
									htmlFor='email'
									className='block text-sm font-medium text-gray-700 mb-2'
								>
									If you'd like to be contacted regarding this
									feature request, please enter your email
								</label>
								<input
									type='email'
									id='email'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									className='w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent'
									placeholder='your@email.com'
								/>
							</div>
							<div className='flex justify-end'>
								<button
									type='submit'
									className='px-6 py-2 bg-teal-600 text-white rounded-lg hover:bg-teal-700 transition-colors'
								>
									Submit Request
								</button>
							</div>
						</form>
					)}
				</div>
			</div>
		</div>
	);
}
