import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faBars } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

export default function Navbar() {
	const { user, logout } = useAuth();
	const location = useLocation();
	const navigate = useNavigate();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const params = new URLSearchParams(location.search);
	const teamId = params.get('teamId');

	// Add debug logging
	console.log('Navbar Debug:', {
		currentPath: location.pathname,
		searchParams: location.search,
		teamId,
		showGoals: Boolean(teamId),
		rawParams: params.toString(),
	});

	// Function to get current teamId from URL
	const getCurrentTeamId = () => {
		const urlParams = new URLSearchParams(window.location.search);
		return urlParams.get('teamId');
	};

	// Get current teamId
	const currentTeamId = getCurrentTeamId();

	return (
		<nav className='bg-white/70 backdrop-blur-lg border-b border-white/10 shadow-lg sticky top-0 z-50'>
			<div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
				<div className='flex justify-between h-16'>
					<div className='flex items-center'>
						<Link
							to='/'
							className='flex items-center gap-2'
						>
							<span className='text-xl font-bold'>
								<span className='bg-gradient-to-r from-teal-600 to-blue-600 bg-clip-text text-transparent'>
									Product Sketch
								</span>
								<span className='ml-2 inline-flex items-center px-2 py-0.5 text-xs font-medium bg-teal-100 text-teal-800 rounded-full'>
									Beta
								</span>
							</span>
						</Link>
					</div>

					{/* Hamburger menu button for mobile */}
					<button
						onClick={() => setIsMenuOpen(!isMenuOpen)}
						className='md:hidden flex items-center'
					>
						<FontAwesomeIcon
							icon={faBars}
							className='h-6 w-6 text-gray-700'
						/>
					</button>

					{/* Desktop menu */}
					<div className='hidden md:flex items-center space-x-4'>
						{user && (
							<>
								<Link
									to={
										currentTeamId
											? `/settings?teamId=${currentTeamId}`
											: '/settings'
									}
									className={`text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium transition-colors ${
										location.pathname === '/settings'
											? 'bg-gray-100'
											: ''
									}`}
								>
									Settings
								</Link>
								<Link
									to='/dashboard'
									className={`${
										location.pathname === '/dashboard'
											? 'border-teal-500 text-gray-900'
											: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
									} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
								>
									Dashboard
								</Link>
							</>
						)}
						<Link
							to='/contribute'
							className={`${
								location.pathname === '/contribute'
									? 'border-teal-500 text-gray-900'
									: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
							} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
						>
							Contribute
						</Link>
						<Link
							to='/roadmap'
							className={`${
								location.pathname === '/roadmap'
									? 'border-teal-500 text-gray-900'
									: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
							} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
						>
							Roadmap
						</Link>
						{user ? (
							<button
								onClick={logout}
								className='text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium transition-colors'
							>
								Logout
							</button>
						) : (
							<Link
								to='/login'
								className='text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium transition-colors'
							>
								Login
							</Link>
						)}
					</div>

					{/* Mobile menu */}
					{isMenuOpen && (
						<div className='md:hidden absolute top-16 right-0 left-0 bg-white border-b border-gray-200 shadow-lg'>
							<div className='px-2 pt-2 pb-3 space-y-1'>
								{user && (
									<>
										<Link
											to={
												currentTeamId
													? `/settings?teamId=${currentTeamId}`
													: '/settings'
											}
											className={`block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 ${
												location.pathname ===
												'/settings'
													? 'bg-gray-100'
													: ''
											}`}
											onClick={() => setIsMenuOpen(false)}
										>
											Settings
										</Link>
										<Link
											to='/dashboard'
											className={`block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 ${
												location.pathname ===
												'/dashboard'
													? 'bg-gray-100'
													: ''
											}`}
											onClick={() => setIsMenuOpen(false)}
										>
											Dashboard
										</Link>
									</>
								)}
								<Link
									to='/contribute'
									className={`block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 ${
										location.pathname === '/contribute'
											? 'bg-gray-100'
											: ''
									}`}
									onClick={() => setIsMenuOpen(false)}
								>
									Contribute
								</Link>
								<Link
									to='/roadmap'
									className={`block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 ${
										location.pathname === '/roadmap'
											? 'bg-gray-100'
											: ''
									}`}
									onClick={() => setIsMenuOpen(false)}
								>
									Roadmap
								</Link>
								{user ? (
									<button
										onClick={() => {
											setIsMenuOpen(false);
											logout();
										}}
										className='block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50'
									>
										Logout
									</button>
								) : (
									<Link
										to='/login'
										className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50'
										onClick={() => setIsMenuOpen(false)}
									>
										Login
									</Link>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</nav>
	);
}
