import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';

export default function LandingNew() {
	const { user } = useAuth();

	return (
		<div className='min-h-screen bg-gradient-to-br from-teal-50 via-emerald-50 to-cyan-100'>
			{/* Hero Section */}
			<section className='container mx-auto px-4 pt-20 pb-32 relative overflow-hidden'>
				<div className='max-w-3xl mx-auto relative z-10'>
					<div className='bg-white/50 backdrop-blur-lg rounded-2xl p-8 shadow-xl border border-white/20'>
						<span className='inline-block px-3 py-1 bg-teal-100 text-teal-700 rounded-full text-sm font-medium mb-4'>
							Simple task management
						</span>
						<h1 className='text-3xl sm:text-4xl md:text-5xl font-bold text-gray-900 mb-4 sm:mb-6 leading-tight'>
							A simple, collaborative{' '}
							<span className='text-teal-600'>task board</span>{' '}
							for small teams
						</h1>
						<p className='text-lg sm:text-xl text-gray-600 mb-6 sm:mb-8 leading-relaxed'>
							Organize tasks, collaborate with your team, and
							focus on what matters most.{' '}
							<span className='font-medium'>
								No complex configurations
							</span>
							, just the features you need.
						</p>
						<div className='mt-10 flex gap-4'>
							{user ? (
								<Link
									to='/dashboard'
									className='px-8 py-4 bg-teal-600 text-white rounded-lg hover:bg-teal-700 transition-colors shadow-lg hover:shadow-xl'
								>
									Go to Dashboard
								</Link>
							) : (
								<div className='flex gap-4'>
									<Link
										to='/login'
										className='rounded-lg bg-teal-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600'
									>
										Get Started
									</Link>
									<Link
										to='/roadmap'
										className='rounded-lg bg-white/10 backdrop-blur-sm px-4 py-2.5 text-sm font-semibold text-teal-600 shadow-sm ring-1 ring-teal-200 hover:bg-white/20 hover:text-teal-500'
									>
										View Roadmap
									</Link>
								</div>
							)}
						</div>
					</div>
				</div>

				{/* Decorative elements */}
				<div className='absolute inset-0 z-0'>
					<div className='absolute top-20 right-1/4 w-64 h-64 bg-teal-200 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob'></div>
					<div className='absolute top-20 left-1/4 w-64 h-64 bg-cyan-200 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000'></div>
					<div className='absolute bottom-20 right-1/3 w-64 h-64 bg-emerald-200 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000'></div>
				</div>
			</section>

			{/* Features Section */}
			<section className='py-16 bg-white'>
				<div className='container mx-auto px-4'>
					<div className='max-w-6xl mx-auto space-y-32'>
						{/* Feature 1: Simple */}
						<div className='flex flex-col lg:flex-row gap-12 items-center'>
							<div className='flex-1 max-w-sm'>
								<h2 className='text-2xl font-semibold mb-4'>
									Simple and straightforward
								</h2>
								<p className='text-gray-600'>
									A clean, intuitive interface that gets out
									of your way. Create tasks, assign them, and
									track progress without any fuss.
								</p>
							</div>
							<div className='flex-[1.5]'>
								<img
									src='/columns_ss.png'
									alt='Task board columns view'
									className='rounded-xl shadow-xl w-full hover:scale-[1.02] transition-transform duration-300'
								/>
							</div>
						</div>

						{/* Feature 2: Full App */}
						<div className='flex flex-col lg:flex-row-reverse gap-12 items-center'>
							<div className='flex-1 max-w-sm'>
								<h2 className='text-2xl font-semibold mb-4'>
									Built for teams
								</h2>
								<p className='text-gray-600'>
									Invite team members, assign tasks, and work
									together in real-time. Perfect for small to
									medium-sized development teams.
								</p>
							</div>
							<div className='flex-[1.5]'>
								<img
									src='/invite.png'
									alt='Full application interface'
									className='rounded-xl shadow-xl w-full hover:scale-[1.02] transition-transform duration-300'
								/>
							</div>
						</div>

						{/* Feature 3: Effort/Impact Matrix */}
						<div className='flex flex-col lg:flex-row gap-12 items-center'>
							<div className='flex-1 max-w-sm'>
								<h2 className='text-2xl font-semibold mb-4'>
									Prioritize with effort/impact matrix
								</h2>
								<p className='text-gray-600'>
									Visualize tasks based on their effort and
									potential impact. Make better decisions
									about what to tackle first.
								</p>
							</div>
							<div className='flex-[1.5]'>
								<img
									src='/matrix_ss.png'
									alt='Effort/Impact matrix view'
									className='rounded-xl shadow-xl w-full hover:scale-[1.02] transition-transform duration-300'
								/>
							</div>
						</div>

						{/* Feature 4: Export */}
						<div className='flex flex-col lg:flex-row-reverse gap-12 items-center'>
							<div className='flex-1 max-w-sm'>
								<h2 className='text-2xl font-semibold mb-4'>
									Export your data
								</h2>
								<p className='text-gray-600'>
									Need to share your task list? Export it to
									markdown format for easy sharing and
									reporting. Perfect for meetings and
									documentation.
								</p>
							</div>
							<div className='flex-[1.5]'>
								<img
									src='/export.png'
									alt='Export functionality'
									className='rounded-xl shadow-xl w-full hover:scale-[1.02] transition-transform duration-300'
								/>
							</div>
						</div>

						{/* Feature 5: Mobile Optimized */}
						<div className='flex flex-col lg:flex-row gap-12 items-center'>
							<div className='flex-1 max-w-sm'>
								<h2 className='text-2xl font-semibold mb-4'>
									Works great on mobile
								</h2>
								<p className='text-gray-600'>
									Access your tasks on the go. The responsive
									design adapts perfectly to your phone or
									tablet, so you can stay productive anywhere.
								</p>
							</div>
							<div className='flex-[1.5] flex justify-center'>
								<img
									src='/mobile.png'
									alt='Mobile interface'
									className='rounded-xl shadow-xl max-w-[300px] hover:scale-[1.02] transition-transform duration-300'
								/>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Simple CTA */}
			<section className='py-16 bg-gradient-to-br from-teal-50 via-emerald-50 to-cyan-100'>
				<div className='container mx-auto px-4 text-center'>
					<div className='max-w-2xl mx-auto'>
						<h2 className='text-2xl font-semibold mb-6'>
							Ready to organize your tasks?
						</h2>
						{user ? (
							<Link
								to='/dashboard'
								className='inline-block px-6 py-3 bg-teal-600 text-white rounded-lg hover:bg-teal-700 transition-colors'
							>
								Go to Dashboard
							</Link>
						) : (
							<Link
								to='/login'
								className='inline-block px-6 py-3 bg-teal-600 text-white rounded-lg hover:bg-teal-700 transition-colors'
							>
								Get Started
							</Link>
						)}
					</div>
				</div>
			</section>
		</div>
	);
}
