import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import Login from './components/Login';
import TeamBoard from './components/TeamBoard';
import Navbar from './components/Navbar';
import Settings from './components/Settings';
import OnboardingScreen from './components/OnboardingScreen';
import Goals from './components/Goals';
// import Landing from './components/Landing';
import AcceptInvite from './components/AcceptInvite';
import LandingNew from './components/LandingNew';
import Contribute from './components/Contribute';
import ThankYou from './components/ThankYou';
import Footer from './components/Footer';
import Roadmap from './components/Roadmap';
import Feedback from './components/Feedback';
import { useEffect } from 'react';
import { initGA, logPageView } from './services/analytics';

export default function App() {
	const { user, userProfile } = useAuth();
	const location = useLocation();

	useEffect(() => {
		// Initialize GA
		initGA();
	}, []);

	useEffect(() => {
		// Track page views on route change
		logPageView();
	}, [location]);

	return (
		<div className='min-h-screen bg-gradient-to-br from-teal-100 via-emerald-50 to-cyan-200'>
			<div className='min-h-screen backdrop-blur-3xl flex flex-col'>
				<Navbar />
				<div className='flex-grow'>
					<Routes>
						<Route
							path='/accept-invite'
							element={<AcceptInvite />}
						/>
						<Route
							path='/login'
							element={
								user ? (
									<Navigate
										to={
											new URLSearchParams(
												window.location.search
											).get('redirect') || '/'
										}
									/>
								) : (
									<Login />
								)
							}
						/>
						<Route
							path='/'
							element={(() => {
								console.log('User exists:', !!user);
								console.log(
									'Not pricing path:',
									!location.pathname.includes('/contribute')
								);
								return user &&
									!location.pathname.includes(
										'/contribute'
									) ? (
									<Navigate
										to='/dashboard'
										replace
									/>
								) : (
									<LandingNew />
								);
							})()}
						/>
						<Route
							path='/dashboard'
							element={
								!user ? (
									<Navigate to='/login' />
								) : !userProfile?.username ? (
									<OnboardingScreen />
								) : (
									<TeamBoard />
								)
							}
						/>
						{/* <Route 
              path="/goals" 
              element={
                !user 
                  ? <Navigate to="/login" />
                  : !userProfile?.username 
                    ? <OnboardingScreen />
                    : <Goals teamId={new URLSearchParams(window.location.search).get('teamId')} />
              } 
            /> */}
						<Route
							path='/settings'
							element={
								!user ? (
									<Navigate to='/login' />
								) : !userProfile?.username ? (
									<OnboardingScreen />
								) : (
									<Settings />
								)
							}
						/>
						<Route
							path='/contribute'
							element={<Contribute />}
						/>
						<Route
							path='/thank-you'
							element={<ThankYou />}
						/>
						<Route
							path='/roadmap'
							element={<Roadmap />}
						/>
						<Route
							path='/feedback'
							element={<Feedback />}
						/>
					</Routes>
				</div>
				<Footer />
			</div>
		</div>
	);
}
